const userAgent = window.navigator?.userAgent?.toLowerCase?.() || ''
// @ts-ignore Checking documentMode to detect IE
const isIEbyDocumentMode = !!window.document?.documentMode
// eslint-disable-next-line
const isIEByUA = !!(userAgent.match(/msie\s([\d.]+)/) || userAgent.match(/trident\/[\d](?=[^\?]+).*rv:([0-9.].)/))
const isIE = isIEbyDocumentMode || isIEByUA
const isOldBrowser = !supportsCssVars() || !supportsCssGrid() || !supportsES6()

if (isIE || isOldBrowser) {
	window.__browser_deprecation__ = true // Used to stop the JS flow in clientRunner

	hideBody() // Hide the HTML generated by SSR

	// Continue the flow (runDeprecationFlow) when DOM is ready
	if (document.readyState === 'complete') {
		runDeprecationFlow()
	} else {
		document.addEventListener('readystatechange', function onReadyStateChange() {
			if (document.readyState === 'complete') {
				runDeprecationFlow()
			}
		})
	}
}

function runDeprecationFlow() {
	clearDomFromSSR()
	showDeprecationMessage()
	reportBI()
}

function hideBody() {
	const head = document.head || document.getElementsByTagName('head')[0]
	const style = document.createElement('style')

	style.setAttribute('type', 'text/css')
	style.appendChild(document.createTextNode('body { visibility: hidden; }'))

	head.appendChild(style)
}

function clearDomFromSSR() {
	const siteContainer = document.getElementById('SITE_CONTAINER')
	siteContainer && (siteContainer.innerHTML = '')
}

function showDeprecationMessage() {
	const iframe = document.createElement('iframe')
	const iframeUrl = isIE ? getIframeUrlForInternetExplorerMessage() : getIframeUrlForOldBrowserMessage()

	iframe.setAttribute('src', iframeUrl)
	iframe.setAttribute('style', 'position: fixed; top: 0; left: 0; width: 100%; height: 100%')
	iframe.onload = function () {
		document.body.style.visibility = 'visible'
	}

	document.body.appendChild(iframe)
}

function getIframeUrlForInternetExplorerMessage() {
	const userLanguage = window.viewerModel?.language.userLanguage || 'en'
	const supportedLanguages: Record<string, 1> = { pt: 1, fr: 1, es: 1, de: 1, ja: 1, ru: 1, ko: 1 }
	const messageLanguage = supportedLanguages[userLanguage] ? userLanguage : 'en'

	// Need to make sure these sites are excluded from TB, otherwise we'll have an inception of deprecation iframes
	return `https://${messageLanguage}.wix.com/outdated-browser/internet-explorer?forceBolt&ssrIndicator=false&suppressbi=true`
}

function getIframeUrlForOldBrowserMessage() {
	const userLanguage = window.viewerModel?.language.userLanguage || 'en'
	const supportedLanguages: Record<string, 1> = { pt: 1, fr: 1, es: 1, de: 1, ja: 1 }
	const messageLanguage = supportedLanguages[userLanguage] ? userLanguage : 'en'

	// eslint-disable-next-line
	const isAndroid = !!userAgent.match(/(android);?[\s\/]+([\d.]+)?/)
	const isIOS = !!userAgent.match(/ipad|iphone|ipod/)
	const isDesktop = window.viewerModel?.viewMode === 'desktop'
	const device = (isDesktop ? 'desktop' : '') || (isIOS ? 'ios' : '') || (isAndroid ? 'android' : '') || 'desktop'

	const domains = {
		ios: 'browseroutofdateios.com',
		android: 'browseroutofdateand.com',
		desktop: 'browseroutofdatedes.com',
	}

	// Need to make sure these sites are excluded from TB, otherwise we'll have an inception of deprecation iframes
	return `https://www.${domains[device]}?lang=${messageLanguage}&forceBolt&ssrIndicator=false&suppressbi=true`
}

function reportBI() {
	window.fedops.phaseStarted('browser_not_supported')
}

function supportsCssVars() {
	const styleElement = document.createElement('style')

	styleElement.innerHTML = ':root { --tmp-var: bold; }'
	document.head.appendChild(styleElement)
	const isSupported = !!(window.CSS && window.CSS.supports && window.CSS.supports('font-weight', 'var(--tmp-var)'))
	styleElement.parentNode?.removeChild?.(styleElement)

	return isSupported
}

function supportsCssGrid() {
	const el = document.createElement('div')
	return typeof el.style.grid === 'string'
}

function supportsES6() {
	if (!window.wixShouldDeprecateES5) {
		return true
	}

	try {
		/* eslint-disable no-new-func */
		new Function('let x = 1')
		new Function('const x = `1`')
		new Function('class X {}')
		new Function('const x = (a = 0, ...b) => a')
		new Function('const x = {...Object}')
		new Function('const y = 1; const x = {y}')
		/* eslint-enable no-new-func */
	} catch (e) {
		return false
	}

	return true
}
